<template>
    <highcharts :options="tagsChart" class="pres_hc"></highcharts>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  components: {
    highcharts: Chart,
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({

  }),

  methods: {

  },
  computed: {
    tagsChart() {
      //console.log(this.data);
      const that = this;
      const data = [...this.data].sort( (a,b)=>Number(b.weight)-Number(a.weight))
      return {
        chart: {
          height: "60%",
          x: 0,
          y: 0,
          marginLeft: 0,
          marginTop: 30,
        },
        plotOptions: {
          wordcloud: {
            minFontSize: 8,
            maxFontSize: 17,
          }
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        series: [
          {
            type: "wordcloud",
            data: data.slice(0, 50),
            name: "Количество"
          }
        ],
        title: {
          text: that.title
        }
      };
    },
  },

};
</script>

<style>
  .pres_hc {border-radius: 4px;}
</style>
