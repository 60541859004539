<template>
  <v-container
    :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'"
    ref="content_card"
    id="content_card"
  >
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <stories-news-filters
          @presDataClick="getReport"
          :startCityId = "178"
        />
        <v-row v-if="tableData.length>0" class="mt-2 mb-2">
          <v-col cols="6">
            <pres-tags-chart :data="this.$store.getters.getTags" :title="`Упоминаемые темы`" />
          </v-col>
          <v-col cols="6">
            <pres-tags-chart :data="this.$store.getters.getPersons" :title="`Упоминаемые персоны`"/>
          </v-col>
        </v-row>
        <v-card
          outlined
          class="mt-2"
          v-if="chart_loading === false"
          id="highcharts_png"
          >
          <v-card-text>
            <v-col class="ml-0 pl-0">
              <div v-if="!tableData.length">Данных не найдено.</div>
              <v-tabs
                v-if="tableData.length > 0"
                v-model="currentTab"
                class="mh-demo-tabs"
                style="margin-bottom: 12px"
              >
                <v-tab
                  class="mh-tab body-2"
                >
                  Сюжеты
                </v-tab>
                <v-tab
                  class="mh-tab body-2"
                >
                  Темы
                </v-tab>
                <v-tab
                  class="mh-tab body-2"
                >
                  Персоны
                </v-tab>
            </v-tabs>
              <pres-table-stories 
                v-if="currentTab === 0" 
                :data="tableData" 
                @rowClick="(item)=>playPoint(item)"
              />
              <pres-table-tags 
                v-if="currentTab === 1" 
                :data="tableData"
                :filter="this.tags"
                @rowClick="(item) => {showTagsPopup(item)}" 
              />
              <pres-table-persons 
                v-if="currentTab === 2" 
                :data="tableData"
                :filter="this.persons"
                @rowClick="(item) => { showTagsPopup(item) }" 
              />
            </v-col>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          height="400"
          v-if="chart_loading === true"
        >
          <base-loader></base-loader>
        </v-card>
        <v-row
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
          v-if="chart_loading === null"
        >
          <pre-report-mount />
        </v-row>
      </v-col>
    </v-row>
    <video-player
      :shown="videoPlayerOn"
      :dt="videoPlayerDt"
      :videoChannelId="videoPlayerChannel"
      :videoChannelName="videoPlayerChannelName"
      :valueName="videoPlayerValueName"
      :values="videoPlayerValues"
      :program="videoPlayerProgram"
      @close="videoPlayerOn = false"
    />
    <tags-popup
      :shown="tagsPopupOn"
      :data="tagsPopupData"
      @close="tagsPopupOn = false"
    />
  </v-container>
</template>
<style scoped>
</style>

<script>
//import html2canvas from "html2canvas";
//import moment from "moment";
//import { Chart } from "highcharts-vue";
import axios from "axios";
import PresTableStories from "@/components/PresTableStories.vue";
import PresTableTags from "@/components/PresTableTags.vue";
import PresTablePersons from "@/components/PresTablePersons.vue";
import StoriesNewsFilters from "@/components/StoriesNewsFilters.vue";
import BaseLoader from "@/components/BaseLoader.vue";
import PreReportMount from "@/components/PreReportMount.vue";
import VideoPlayer from "@/components/VideoPlayerStories.vue";
import TagsPopup from "@/components/PresStoriesTagsPopup.vue";
import PresTagsChart from "@/components/PresTagsChart.vue";

import { CONSTS } from "../services/constants.js";
//import Utils from "../services/utils";

export default {
  async mounted() {
    if (!this.hasPriv) this.$router.push("/");
    window.addEventListener("resize", this.handleResize, true);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: {
    PresTableStories,
    PresTableTags,
    PresTablePersons,
    StoriesNewsFilters,
    BaseLoader,
    VideoPlayer,
    PreReportMount,
    PresTagsChart,
    TagsPopup,
    //highcharts: Chart,
  },
  data: () => ({
    chart_loading: null,
    currentTab: 0,
    tableData: [], // данные для построения 3-х таблиц
    tagsPopupOn: false, // если true - открыт диалог просотра тегов второй и третьей таблицы
    tagsPopupData: null,
    videoPlayerOn: false, // если true - открыт диалог проигрывания видео
    videoPlayerChannel: null,
    videoPlayerChannelName: null,
    videoPlayerValueName: null, // название отображаемого показтеля
    videoPlayerDt: null,
    videoPlayerProgram: null, // расписание передач
    videoPlayerValues: null, // помнутные значения выбранного показателя
    tags: null, //выбранные для отчета темы
    persons: null, //выбранные для отчета персоны
  }),
  computed: {
    hasPriv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_pres
      ];
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },

    chartTitle() {
      return "";
    },
  },
  methods: {
    handleResize() {
      //this.contentWidth = document.getElementById("content_card").clientWidth;
      //this.contentWidth = this.contentWidth - 70 - 20; // вычитаем отсупы внутри и снаружи графика
    },

    showTagsPopup(item) {
      this.tagsPopupOn = true;
      this.tagsPopupData = item;
    },
    playPoint({efir, sdate, edate, tvchannel, event, subject, tags, persons, contacts, appeal}) {
      console.log(
        `Play: ${efir} (${tvchannel}) time: ${sdate} - ${edate} ${this.videoPlayerOn}`
      );

      if (this.videoPlayerOn) {
        this.videoPlayerOn = false;
        return setTimeout(() =>
          this.playPoint(
            { efir, sdate, edate, tvchannel, event, subject, tags, persons, contacts, appeal }
          )
        );
      }
      const description = [...tags, ...persons].map(s=>'#'+s).join(", ")
      this.videoPlayerChannel = efir;
      if (sdate) {
        this.videoPlayerChannelName = `${tvchannel} Москва`;
      } else {
        this.videoPlayerChannelName = tvchannel;
      }  
      this.videoPlayerValueName = "test";
      this.videoPlayerDt = sdate;
      this.videoPlayerProgram = {
        subject,
        event,
        description,
        sdate,
        edate,
        contacts,
        appeal
      };
      this.videoPlayerValues = 0;
      this.videoPlayerOn = true;
    },

    async getReport(data) {
      //console.log(data)
      const that = this;
      this.persons = data.pers;
      this.tags = data.tags;
      this.chart_loading = true;

      const CancelToken = axios.CancelToken;
      const reqData = new FormData();
      reqData.append("city_id", data.cityId);
      reqData.append("sdate", data.range.start);
      reqData.append("edate", data.range.end);
      reqData.append("through", data.range.through);
      reqData.append("weekdays", data.range.weekdays);
      reqData.append("shows", data.shows);
      reqData.append("tags", data.tags);
      reqData.append("pers", data.pers);
      let res;
      try {
        res = await axios.post("stories/report", reqData, {
          cancelToken: new CancelToken(function executor(c) {
            that.axiosCancel = c;
          }),
        });
      } catch (e) {
        this.chart_loading = null;
        this.isStoped = true;
      }
      if (this.isStoped) return;
      this.chart_loading = false;
      this.tableData = [];
      if (res && res.data && res.data.results) this.tableData = res.data.results;
      //console.log(this.tableData);
    },
  },
  watch: {
    currentTab(val) {
      console.log(val)
    },
    "$route.path": function () {
      console.log(this.$route.name);
    },
  },
};
</script>
